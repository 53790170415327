<template>
    <div class="co_contain">
        <div class="customer_hd_bg">
            <img :src="customer_hd_bg" alt="">
        </div>
        <div class="contain_down">
            <div class="bottom-contain">
                <div class="left-bts">
                    <div :class="activebtid === index ? 'bt btactive' : 'bt'" v-for="(item, index) in btlist"
                        :key="index" @click="clickbt(index, item)">
                        <template v-if="activebtid === index">
                            <div class="bt-text bt-text_active">{{ item.name }}</div>
                        </template>
                        <template v-else>
                            <div class="bt-text">{{ item.name }}</div>
                        </template>
                    </div>
                </div>

                <div class="right-contain">
                    <div class="contain_input">
                        <el-input placeholder="请输入问题" v-model="search" clearable>
                            <el-button slot="append" icon="el-icon-search" @click="searchQ()"></el-button>
                        </el-input>
                    </div>
                    <div class="FAQ_list_cont" v-loading="loading">
                        <div class="FAQ_list" v-if="flag">
                            <div class="list_cont" :class="FAQList.length > 0 ? '' : 'is_empty'">
                                <div class="list_cont_block" v-if="FAQList.length > 0">
                                    <div v-for="(item, inx) in FAQList" :key="inx" class="list_FAQ_item"
                                        @click="to_detail(item)">
                                        {{ item.title }}
                                    </div>
                                </div>
                                <el-empty v-else :image-size="90"></el-empty>
                            </div>
                            <!-- 分页 -->
                            <pag-table class="paginationBox" :total="total" :page="queryParams.page"
                                :limit="queryParams.per_page" @changeSize="changeSize" @changePage="changePage" />
                        </div>
                        <div class="FAQ_detail" v-else>
                            <div class="question_title">{{ detailData.title }}</div>
                            <div class="question_desc" v-html="detailData.answer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--右下角客服按钮-->
        <div class="customer_service">
            <div class="customer_service_cont">
                <div class="icons_cont">
                    <div v-for="(item, inx) in infoList" :key="inx" class="icons_cont_item" @mouseover="showTip(inx)">
                        <img :src="item.src" alt="">
                    </div>
                </div>
                <div class="text_cont">
                    <div v-for="(item, inx) in infoList" :key="inx" class="text_cont_item">
                        <div class="text_cont_explain">{{ item.explain }}</div>
                        <div class="text_cont_phone">{{ item.phone }}</div>
                    </div>
                </div>
            </div>
        </div>
        <el-footer class="el-footer">
            <Footer></Footer>
        </el-footer>
    </div>
</template>
<script>
import Footer from "@/views/Footer";
import pagTable from '@/components/Pagination/pagTable'
import { gettypeconfiglist, getProblemList, getProblemInfo } from "@/api/typeconfig";
export default {
    name: "customerService",
    components: {
        Footer,
        pagTable
    },

    data() {
        return {
            activebtid: 0,
            btlist: [],
            customer_img: require("@/assets/customer/customer_service.png"),
            customer_hd_bg: require("@/assets/customer/banner.png"),
            infoList: [
                {
                    src: require("@/assets/customer/qq.png"),
                    explain: '平台使用问题咨询QQ',
                    phone: '417241957'
                },
                {
                    src: require("@/assets/customer/telephone.png"),
                    explain: '平台使用问题咨询电话',
                    phone: '18923885135'
                },
                {
                    src: require("@/assets/customer/landline.png"),
                    explain: '教务办公室联系方式',
                    phone: '027-87285075'
                }
            ],
            queryParams: {
                keyword: '', // 关键字
                status: '',  // 发布状态
                type_config_id: '', // 字典分类id（常见问题分类）
                per_page: 10, // 每页条数
                page: 1
            },
            total: 0,
            search: '',
            FAQList: [],
            detailData: '',
            flag: true,
            QQ_src: '',
            index: 0,
            loading: false,
        };
    },
    watch: {
    },
    created() {
        this.initMenu()
    },
    mounted() {
        this.$nextTick(() => {
            document.addEventListener('click', this.handlerClick)
        })
    },
    methods: {
        initMenu() {
            gettypeconfiglist({ type: 35 }).then(res => {
                if (res.code === 0) {
                    this.btlist = res.data
                    this.clickbt(0, this.btlist[0])
                }
            })
        },
        // 左侧列表点击
        clickbt(index, item) {
            this.reset()
            this.flag = true
            this.activebtid = index
            this.queryParams.type_config_id = item.id
            // 重置参数
            this.queryParams.keyword = ''
            this.queryParams.status = ''
            this.queryParams.page = 1
            this.initList()
        },
        reset() {
            this.total = 0
            this.search = ''
            this.FAQList = []
        },
        // 分页
        changeSize(data) {
            this.queryParams.per_page = parseInt(data)
            this.initList()
        },
        changePage(data) {
            this.queryParams.page = parseInt(data)
            this.initList()
        },
        // 初始化列表数据
        initList() {
            getProblemList(this.queryParams).then(res => {
                if (res.code == 0 && res.data) {
                    this.FAQList = res.data.data
                    this.total = res.data.total
                    this.loading = false
                }
            }).catch(error => {
                this.loading = false
            })
        },
        searchQ() {
            this.loading = true
            this.queryParams.keyword = this.search
            this.flag = true
            this.initList()
        },
        to_detail(data) {
            this.flag = false
            this.getDetail(data)
        },
        getDetail(data) {
            getProblemInfo({ id: data.id }).then(res => {
                this.detailData = res.data
            })
        },
        showTip(inx) {
            // debugger
            this.index = inx
            let dis = document.querySelectorAll('.text_cont_item')
            dis.forEach(item => {
                item.style['visibility'] = 'hidden'
            })
            dis[inx].style['visibility'] = 'visible'
        },
        handlerClick(event) {
            let dis = document.querySelectorAll('.text_cont_item')
            let isSelf = document.querySelector('.icons_cont').contains(event.target)
            let isSelfItem = document.querySelectorAll('.text_cont_item')[this.index].contains(event.target)
            if (!isSelf && !isSelfItem) {
                dis.forEach(item => {
                    item.style['visibility'] = 'hidden'
                })
            }
        },
        to_QQChat(data, inx) {
        }
    },
};
</script>
<style scoped lang="scss">
.co_contain {
    padding: 0px 0px 180px;
    position: relative;
    background-color: #F9F9F9;

    .contain_down {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 120px - 285px - 60px);
        padding: 0px;
        // margin-bottom: 80px;

        .bottom-contain {
            margin: 60px auto 0px;
            width: 1200px;
            display: flex;
            justify-content: flex-start;
            // align-items: flex-start;
            align-items: stretch;

            .left-bts {
                min-width: 220px;
                min-height: 650px;
                background: #ffffff;
                padding: 30px 0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .bt {
                    width: 160px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    user-select: none;
                    cursor: pointer;
                    margin-bottom: 20px;

                    &:hover {
                        background: #3d84ff54;
                        border-radius: 5px;
                    }

                    .bt-icon {
                        margin-right: 10px;
                    }

                    .elimage {
                        margin-right: 10px;
                    }

                    .bt-text {
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }

                    .bt-text_active {
                        color: #ffffff;
                    }
                }

                .btactive {
                    background: #3d84ff;
                    border-radius: 5px;
                }
            }

            .right-contain {
                margin-left: 30px;
                background: #ffffff;
                flex: 1;
                min-height: 650px;
                width: 100%;
                padding: 30px;

                .contain_input {
                    width: 330px;
                    margin-bottom: 12px;

                    ::v-deep .el-input__inner {
                        border-radius: 20px 0px 0px 20px;
                    }

                    ::v-deep .el-input-group__append {
                        border-radius: 0px 20px 20px 0px;
                    }

                    ::v-deep .el-button {
                        padding: 12px 14px;
                    }

                    ::v-deep .el-icon-search {
                        font-size: 18px;
                        color: #3d84ff;
                        font-weight: bold;
                    }

                }

                .FAQ_list {
                    // min-height: 580px;

                    .list_cont_block {
                        height: 100%;
                    }

                    .list_cont {
                        min-height: 580px;
                    }

                    .is_empty {
                        display: flex;
                        justify-content: center;
                    }

                    .list_FAQ_item {
                        cursor: pointer;
                        padding: 18px 0px 18px 10px;
                        font-weight: bold;
                        font-size: 16px;
                    }

                    .list_FAQ_item:nth-child(n+2) {
                        border-top: 1px solid #EEEEEE;
                    }
                }

                .paginationBox {
                    // position: absolute;
                    padding: 16px;
                    text-align: right;
                }

                .FAQ_detail {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 24px;

                    .question_title {
                        padding: 18px 0px 20px;
                        color: #333333;
                        text-align: center;
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .question_desc {
                        color: #666666;

                        ::v-deep p {
                            margin: 0px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .customer_hd_bg {
        // width: 100%;
        // height: 285px;
        // object-fit: cover;


        img {
            width: 100%;
            // height: 100%;
            object-fit: contain;
        }
    }

    .customer_service {
        position: fixed;
        top: 50%;
        right: 1%;
        width: 45px;
        height: 145px;
        background: #FFFFFF;
        box-shadow: 0px 5px 9px 0px rgba(61, 132, 255, 0.26);
        border-radius: 23px;
        // cursor: pointer;

        .customer_service_cont {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .icons_cont {
            width: 45px;
            padding: 22px 0px;
            height: 102px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: space-between;

            div {
                width: 28px;
                height: 28px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .text_cont {
            position: absolute;
            right: calc(56px + 1%);
            top: 4px;

            .text_cont_item {
                min-width: 142px;
                text-align: center;
                padding: 10px 14px;
                background: #FFFFFF;
                border-radius: 5px;
                box-shadow: 0px 5px 9px 0px rgba(61, 132, 255, 0.26);
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                position: relative;
                visibility: hidden;
            }

            .text_cont_item::after {
                position: absolute;
                content: '';
                left: 162px;
                top: 27px;
                width: 12px;
                height: 12px;
                background-color: #ffffff;
                transform: rotateZ(45deg);
                box-shadow: 0px -24px 30px 0px rgb(61 132 255 / 26%);
            }

            .text_cont_item:nth-child(2) {
                transform: translateY(-32px);
            }

            .text_cont_item:nth-child(3) {
                transform: translateY(-64px);
            }

            .text_cont_phone {
                color: #333;
            }
        }
    }

    .el-footer {
        position: absolute;
        height: 120px !important;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }
}
</style>
  